import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button, Modal, ModalHeader, ModalBody, Label } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { setSnackbar } from '../../../redux/ducks/snackbarReducer';
import axios from "axios";
import { CONFIG } from '../../../Utils/config';
import { showLoader, hideLoader } from '../../../redux/ducks/application';
import { normalizeInput, deNormalizeInput, USERTYPES, REGIONS, COUNTRYID } from '../../../Utils/utils';
import icon1 from '../../../Assets/Img/icon1.png';
import icon2 from '../../../Assets/Img/icon2.png';
import icon3 from '../../../Assets/Img/icon3.png';
import icon4 from '../../../Assets/Img/icon4.png';

class UserRegistraton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {},
            errors: {},
            userExist: false,
            passwordError: '',
            filteredState: [],
            stateData: [],
            countryData: [],
            showState: true,
            countryId: CONFIG.REGION_ID === REGIONS.US ? COUNTRYID.US : CONFIG.REGION_ID === REGIONS.UK ? COUNTRYID.UK : 0,
            filteredCountry: [],
        }
        this.handlePhoneChange = this.handlePhoneChange.bind(this);
        this.lrvRegister = this.lrvRegister.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        if (this.props.isUserExist) {
            const { user } = this.state;
            if (this.props.UserID) {
                user.UserID = this.props.UserID;
            }
            this.setState({ userExist: true, user });
        }
        this.getLookupData();

    }

    initializeUserObject() {
        var user = {
            UserID: 0,
            Login: '',
            Password: '',
            ConfirmPassword: '',
            FirstName: '',
            Company: '',
            Phone: '',
            IsBuyer: false,
            IsBroker: false,
            IsManagement: false,
            IsLender: false,
            IsVendor: false,
            IsOperator: false,
            IsOtherUserType: false,
            OtherUserTypes: '',
            BillingAddress: '',
            BillingState: '',
            BillingCity: '',
            BillingCountry: this.state.filteredCountry,
            BillingZipCode: '',
            RegionId: CONFIG.REGION_ID,
        }
        return user;
    }

    onDDLChange(e) {
        let user = this.state.user;
        let errors = this.state.errors;
        user[e.target.name] = e.target.value;
        if (e.target.value !== "0") {
            errors[e.target.name] = "";
        }
        if (e.target.name === "BillingCountry") {
            var sc_code = e.target.value;
            let countryDetails = this.state.countryData.filter(function (data) {
                return data.CountryCode === sc_code
            });
            // State's country code based country details
            let stateDetails = this.state.stateData.filter(function (data) {
                return data.CountryCode === sc_code
            });
            user['BillingCountry'] = stateDetails.length > 0 ? countryDetails[0].CountryCode : '';
            user['BillingCountryCode'] = countryDetails[0] !== undefined && countryDetails.length > 0 ? countryDetails[0].CountryCode : '';
            user.BillingState = '';
            user.BillingCountryCode = countryDetails[0] !== undefined && countryDetails.length > 0 ? countryDetails[0].CountryCode === "England" ? "GB" : countryDetails[0].CountryCode : '';
            this.setState({
                user, errors, filteredState: stateDetails, showState: countryDetails[0].CountryCode === "England" ? false : true, countryId: countryDetails[0] !== undefined ? countryDetails[0].CountryID : null,
            });
        } else {
            this.setState({
                user, errors
            });
        }
    }

    handlePhoneChange({ target: { value } }) {
        const { user } = this.state;
        var phoneError = "";

        if (user.Phone === "") {
            phoneError = "Phone is required";
        }
        user.Phone = normalizeInput(value, user.Phone);
        this.setState({ user, phoneError });
    };

    onUserTypeChange(e) {
        let user = this.state.user;
        user[e.target.name] = !user[e.target.name];
        var errors = this.state.errors;
        if (user.IsBuyer ||
            user.IsBroker ||
            user.IsManagement ||
            user.IsLender ||
            user.IsVendor ||
            user.IsOperator ||
            user.IsOtherUserType) {
            errors["UserType"] = " ";
        } else {
            errors["UserType"] = "Please select any option";
        }
        this.setState({
            user
        });
    }

    onKeyChange(e) {
        let user = this.state.user;
        let errors = this.state.errors;

        user[e.target.name] = e.target.value;
        if (e.target.value.trim() !== "") {
            errors[e.target.name] = "";
        }
        this.setState({
            user, errors
        });
    }

    onEmailChange(e) {
        let user = this.state.user;
        let errors = this.state.errors;

        user[e.target.name] = e.target.value;
        if (e.target.value.trim() !== "") {
            errors[e.target.name] = "";
        }
        this.setState({
            user, errors
        });

    }

    onEmailKeyUp(e) {
        var data = {
            UserEmail: e.target.value,
            RegionID: CONFIG.REGION_ID
        }
        axios.post(CONFIG.API_URL + 'Account/signup/validation', data).then(
            res => {
                var data = res.data;
                var user = null;

                if (data.User && !data.User.IsLRV) {
                    user = this.initializeUserObject();
                    user.UserID = data.User.UserID;
                    this.setState({ userExist: true, user, errors: {} });
                } else if (data.User && data.User.IsLRV) {
                    user = this.initializeUserObject();
                    this.setState({ user, errors: {} });
                    this.props.dispatch(setSnackbar(true, "error", "User already exists"));
                } else if (!data.User) {
                    if (data.Message !== "No data") {
                        user = this.initializeUserObject();
                        this.setState({ user, errors: {} });
                        this.props.dispatch(setSnackbar(true, "error", data.Message));
                    }
                }
            }
        )
            .catch(err => {
                if (err.response !== null && err.response !== undefined) {
                    this.props.dispatch(setSnackbar(true, "error", err.response.data));
                }
                else {
                    this.props.dispatch(setSnackbar(true, "error", "Something wrong happend"));
                }
            });
    }

    onSubmit(event, errors, values) {
        this.props.dispatch(showLoader());
        const { user } = this.state;
        var result = this.validateUser(user);
        if (errors.length === 0 && result) {
            user.RegionId = CONFIG.REGION_ID;
            user.Phone = deNormalizeInput(user.Phone);
            let countryCodes = this.state.countryData.filter(x => x.CountryID === this.state.countryId).map(x => x.CountryCode)[0];
            user['BillingCountry'] = this.state.countryId;
            user['BillingCountryCode'] = countryCodes === 'England' || countryCodes === 'Scotland' || countryCodes === 'Northern Ireland' || countryCodes === 'Wales' ? 'GB' : countryCodes;
            axios.post(CONFIG.API_URL + 'Account/register/', user).then(
                response => {
                    if (response.status === 200) {
                        this.registerRedirect(response);
                    }
                }
            )
                .catch(err => {
                    if (err.response !== null && err.response !== undefined) {
                        this.props.dispatch(setSnackbar(true, "error", err.response.data));
                    }
                    else {
                        this.props.dispatch(setSnackbar(true, "error", "Something wrong happend"));
                    }
                });
        }
        else
            this.props.dispatch(hideLoader());
    }

    validateUser(user) {
        var errors = {};
        var result = true;
        if (CONFIG.REGION_ID !== REGIONS.UK) {
            if (user.BillingCountry === "0" || user.BillingCountry === "") {
                errors["BillingCountry"] = "Country is required";
                result = false;
            }
            if (user.BillingState === "0" || user.BillingState === "") {
                errors["BillingState"] = "State is required";
                result = false;
            }
        }
        if (user.IsBuyer ||
            user.IsBroker ||
            user.IsManagement ||
            user.IsLender ||
            user.IsVendor ||
            user.IsOperator ||
            user.IsOtherUserType) {

        } else {
            errors["UserType"] = "Please select any option";
            result = false;
        }

        if (user.IsOtherUserType) {
            if (user.OtherUserTypes === "") {
                errors["OtherUserTypes"] = "Please specify";
                result = false;
            }
        }
        this.setState({ errors });
        return result;
    }

    lrvRegister() {
        const { user } = this.state;
        var errors = {};
        this.setState({ passwordError: '' });
        var isValid = true;
        this.props.dispatch(showLoader());
        if (user.Password.trim() === "") {
            isValid = false;
        }

        if (user.IsBuyer ||
            user.IsBroker ||
            user.IsManagement ||
            user.IsLender ||
            user.IsVendor ||
            user.IsOperator ||
            user.IsOtherUserType) {

        } else {
            errors["UserType"] = "Atleast select one of the option above";
            isValid = false;
        }

        if (user.IsOtherUserType) {
            if (user.OtherUserTypes === "") {
                errors["OtherUserTypes"] = "Please specify";
                isValid = false;
            }
        }
        this.setState({ errors });
        if (isValid) {
            user['BillingCountry'] = this.state.countryId;
            axios.post(CONFIG.API_URL + 'Account/login/mvp', user).then(
                response => {
                    if (response.status === 200) {
                        this.registerRedirect(response);
                    }
                }
            )
                .catch(err => {
                    if (err.response !== null && err.response !== undefined) {
                        if (err.response.data === "INCORRECT-PASSWORD") {
                            this.setState({ passwordError: 'Password doesn`t match.' });
                        }
                    }
                    else {
                        this.props.dispatch(setSnackbar(true, "error", "Something wrong happend"));
                    }
                });

        }
        else
            this.props.dispatch(hideLoader());
    }

    getLookupData() {
        //States and Country
        let stateValue = 1;
        axios.get(CONFIG.API_URL + 'marketreport/lookupdata/' + CONFIG.REGION_ID + '/' + stateValue)
            .then(response => {
                let stateData = response.data.lookups.StatesList;
                let countryData = response.data.lookups.CountryList;
                let countryCode = countryData.filter(x => x.CountryID === this.state.countryId).map(x => x.CountryCode)[0];

                let statedetail = [];
                if (countryCode !== undefined && countryCode !== null && countryCode !== '') {
                    statedetail = stateData.filter(function (data) {
                        return data.CountryCode === countryCode
                    });
                }
                this.setState({ stateData, countryData, filteredCountry: countryCode, filteredState: statedetail, showState: statedetail && statedetail.length > 0 ? countryCode === 'England' || countryCode === 'Scotland' || countryCode === 'Northern Ireland' || countryCode === 'Wales' ? false : true : false }, () => {
                    if (!this.state.userExist) {
                        this.setState({ user: this.initializeUserObject() });
                    }
                });

            })
            .catch(err => {
                this.setState({ isLoading: false });
            });
    }
    registerRedirect(response) {
        const getTokvalues = response.data.Token;
        localStorage.setItem('accessKey', response.data.Token);
        localStorage.setItem('name', response.data.FirstName + (response.data.LastName !== null ? ' ' + response.data.LastName : ''));
        localStorage.setItem('email', response.data.Login);
        localStorage.setItem('role', response.data.UserType);
        localStorage.setItem('id', response.data.UserID);
        localStorage.setItem('secureid', response.data.SecureID);
        localStorage.setItem('stuserstatus', response.data.Status);
        localStorage.setItem('brokertype', response.data.BrokerTypeId);
        axios.defaults.headers.common["Authorization"] = 'Bearer ' + getTokvalues;

        if (response.data.UserType === USERTYPES.Admin) {

            const { from } = { from: { pathname: "/admin/usermanagement" } };
            if (this.props.history) {
                this.props.history.push(from);
            } else {
                this.props.headerProps.parm.children.props.history.push(from);
            }

        } else {
            const { from } = { from: { pathname: "/user/dashboard" } };
            if (this.props.history) {
                this.props.history.push(from);
            } else {
                this.props.headerProps.parm.children.props.history.push(from);
            }
        }


    }

    toggleModalClose() {

        var user = this.initializeUserObject();
        this.setState({ userExist: false, user, errors: {} });

        if (this.props.isUserExist) {
            this.props.parentMethod();
        }

    }

    render() {
        const { errors, user, userExist, passwordError, filteredState, showState, countryData} = this.state;
        return (
            <div className="userregistration">
                <Modal size="md" id="mdSeller" name="mdSeller" backdrop="static" isOpen={userExist} toggle={this.toggleModalClose.bind(this)} className="vendor-assign-table">
                    <ModalHeader toggle={this.toggleModalClose.bind(this)}>You have an existing StorTrack account with this email address! Select one or more of the below options and proceed.
                    </ModalHeader>
                    <ModalBody className="overflow-scroll">
                        <h5 className="youarea">You are a</h5>
                        <div className="popup-regisration-selectuser fliter-search">
                            <AvForm onSubmit={this.lrvRegister.bind(this)} ref={c => (this.formUsers = c)} id="ListRV_user_registration_form">
                                <Row className="margin-b-10 margin-select-user-row">
                                    <div className="checkbox-signup-user margin-b-6">
                                        <AvField type="checkbox" className="checkbox-align" name="IsBuyer" checked={user.IsBuyer}
                                            onChange={(e) => this.onUserTypeChange(e)} /> <span>Buyer</span>
                                    </div>
                                    <div className="checkbox-signup-user margin-b-6">
                                        <AvField type="checkbox" className="checkbox-align" name="IsBroker" checked={user.IsBroker}
                                            onChange={(e) => this.onUserTypeChange(e)} /> <span>Broker</span>
                                    </div>
                                    <div className="checkbox-signup-user margin-b-6">
                                        <AvField type="checkbox" className="checkbox-align" name="IsManagement" checked={user.IsManagement}
                                            onChange={(e) => this.onUserTypeChange(e)} /> <span>Management Co</span>
                                    </div>
                                    <div className="checkbox-signup-user margin-b-6">
                                        <AvField type="checkbox" className="checkbox-align" name="IsLender" checked={user.IsLender}
                                            onChange={(e) => this.onUserTypeChange(e)} /> <span>Lender</span>
                                    </div>
                                    <div className="checkbox-signup-user margin-b-6">
                                        <AvField type="checkbox" className="checkbox-align" name="IsVendor" checked={user.IsVendor}
                                            onChange={(e) => this.onUserTypeChange(e)} /> <span>Vendor</span>
                                    </div>
                                    <div className="checkbox-signup-user margin-b-6">
                                        <AvField type="checkbox" className="checkbox-align" name="IsOperator" checked={user.IsOperator}
                                            onChange={(e) => this.onUserTypeChange(e)} /> <span>Operator</span>
                                    </div>
                                    <div className="checkbox-signup-user margin-b-6">
                                        <AvField type="checkbox" className="checkbox-align" name="IsOtherUserType" checked={user.IsOtherUserType}
                                            onChange={(e) => this.onUserTypeChange(e)} /> <span>Other</span>
                                    </div>
                                    <span className="errorMessage error-user-block-120"> {errors.UserType}</span>
                                    {user.IsOtherUserType ?
                                        <Row className="specify-user need-margin">
                                            <AvField name="OtherUserTypes" placeholder="Please specify:*" type="text" maxLength="100"
                                                value={user.OtherUserTypes} onChange={(e) => this.onKeyChange(e)} />
                                            <span className="errorMessage error-user-block-146"> {errors.OtherUserTypes}</span>
                                        </Row>
                                        : ''}
                                </Row>
                                <h5 className="youarea margin-b-5">Password:</h5>
                                <Row className="popup-password-email">
                                    <Col md={12}>
                                        <AvField name="Password" placeholder="Your Password" type="password"
                                            value={user.Password} onChange={(e) => this.onKeyChange(e)}
                                            validate={{
                                                required: { value: true, errorMessage: 'Password is required' },
                                            }} />
                                    </Col>
                                    <span className="errorMessage error-user-block-90"> {passwordError}</span>
                                </Row>
                                <Row className="need-margin">
                                    <div className="add-new-btn margin-left-auto">
                                        <Button type="submit">GO TO DASHBOARD</Button>
                                    </div>
                                </Row>
                            </AvForm>
                        </div>
                    </ModalBody>
                </Modal>
                {!this.props.isUserExist ?
                    <div className="col-sm-7 box-center">
                        <Row className="width-100 row-width">
                            <Col md={5} className="background-signup">
                                <h6>Why Register?</h6>
                                <h2><img src={icon1} alt="Registered User" /> For all registered users</h2>
                                {CONFIG.REGION_ID === REGIONS.US ?
                                    <p>Get free access to premium market data across the US and Canada</p>
                                    : CONFIG.REGION_ID === REGIONS.UK ?
                                        <p>Get free access to premium market data across the UK</p>
                                        : ""}
                                <h2><img src={icon2} alt="Buyers" />For Buyers</h2>
                                <p>Create a WatchList and buy discounted credits to access market data with the click of a button</p>
                                <h2><img src={icon3} alt="Brokers" />For Brokers</h2>
                                <p>Post listings, manage your profile and view buyer inquiries</p>
                                <h2><img src={icon4} alt="Vendors" />For Vendors</h2>
                                <p>Directly manage your vendor profile, see inquiries and new leads</p>
                            </Col>
                            <Col md={7} className="signup-form">
                                <h6>Create an Account</h6>
                                <AvForm onSubmit={this.onSubmit.bind(this)} ref={c => (this.form = c)} id="ListRV_create_account_form">
                                    <Row>
                                        <AvField name="Login" placeholder="Email" type="text" maxLength="100"
                                            value={user.Login} onChange={(e) => this.onEmailChange(e)} onBlur={(e) => this.onEmailKeyUp(e)}
                                            validate={{
                                                required: { value: true, errorMessage: 'Email is required' },
                                                pattern: { value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,7}$/, errorMessage: 'Email is invalid' }
                                            }}
                                        />
                                        <span className="errorMessage"> {errors.Login}</span>
                                    </Row>
                                    <Row className="password-error">
                                    <Col md={6} className="padding-remove-left">
                                        <AvField name="Password" placeholder="Create Password" type="password"
                                            value={user.Password} onChange={(e) => this.onKeyChange(e)}
                                            validate={{
                                                required: { value: true, errorMessage: 'Password is required' },
                                                pattern: { value: '^(.{0,}(([a-zA-Z][^a-zA-Z])|([^a-zA-Z][a-zA-Z])).{4,})|(.{1,}(([a-zA-Z][^a-zA-Z])|([^a-zA-Z][a-zA-Z])).{3,})|(.{2,}(([a-zA-Z][^a-zA-Z])|([^a-zA-Z][a-zA-Z])).{2,})|(.{3,}(([a-zA-Z][^a-zA-Z])|([^a-zA-Z][a-zA-Z])).{1,})|(.{4,}(([a-zA-Z][^a-zA-Z])|([^a-zA-Z][a-zA-Z])).{0,})$', errorMessage: 'Passwords are case-sensitive, must be between 6 to 25 characters and contain at least 1 letter and 1 number or special character.' },
                                                minLength: { value: 6, errorMessage: 'Your Password must be 6 characters' },
                                                maxLength: { value: 25, errorMessage: 'Your Password must be 25 characters' }
                                            }} />
                                            </Col>
                                   
                                    <Col md={6} className="padding-remove-right">
                                        <AvField name="ConfirmPassword" placeholder="Confirm Password" type="password" validate={{
                                            required: { value: true, errorMessage: 'Confirm Password is required' },
                                            match: { value: 'Password', errorMessage: 'Mismatch Confirm Password' }
                                        }} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <h5>You are a </h5>
                                    </Row>
                                    <Row className="margin-b-10 ">
                                        <div className="checkbox-signup checkbox-signup-80">
                                            <Label>
                                                <AvField type="checkbox" className="checkbox-align" name="IsBuyer" checked={user.IsBuyer}
                                                    onChange={(e) => this.onUserTypeChange(e)} /> <span>Buyer</span>
                                            </Label>
                                        </div>
                                        <div className="checkbox-signup checkbox-signup-80">
                                            <Label>
                                                <AvField type="checkbox" className="checkbox-align" name="IsBroker" checked={user.IsBroker}
                                                    onChange={(e) => this.onUserTypeChange(e)} /> <span>Broker</span>
                                            </Label>
                                        </div>
                                        <div className="checkbox-signup">
                                            <Label>
                                                <AvField type="checkbox" className="checkbox-align" name="IsManagement" checked={user.IsManagement}
                                                    onChange={(e) => this.onUserTypeChange(e)} /> <span>Management Co</span>
                                            </Label>
                                        </div>
                                        <div className="checkbox-signup checkbox-signup-80">
                                            <Label>
                                                <AvField type="checkbox" className="checkbox-align" name="IsLender" checked={user.IsLender}
                                                    onChange={(e) => this.onUserTypeChange(e)} /> <span>Lender</span>
                                            </Label>
                                        </div>
                                        <div className="checkbox-signup checkbox-signup-80">
                                            <Label>
                                                <AvField type="checkbox" className="checkbox-align" name="IsVendor" checked={user.IsVendor}
                                                    onChange={(e) => this.onUserTypeChange(e)} /> <span>Vendor</span>
                                            </Label>
                                        </div>
                                        <div className="checkbox-signup checkbox-signup-80">
                                            <Label>
                                                <AvField type="checkbox" className="checkbox-align" name="IsOperator" checked={user.IsOperator}
                                                    onChange={(e) => this.onUserTypeChange(e)} /> <span>Operator</span>
                                            </Label>
                                        </div>
                                        <div className="checkbox-signup checkbox-signup-80">
                                            <Label>
                                                <AvField type="checkbox" className="checkbox-align" name="IsOtherUserType" checked={user.IsOtherUserType}
                                                    onChange={(e) => this.onUserTypeChange(e)} /> <span>Other</span>
                                            </Label>
                                        </div>
                                        <span className="errorMessage error-user-block-289"> {errors.UserType}</span>
                                        {user.IsOtherUserType ?
                                            <Row className="register-user-type">
                                                <AvField name="OtherUserTypes" placeholder="Please specify" type="text" maxLength="100"
                                                    value={user.OtherUserTypes} onChange={(e) => this.onKeyChange(e)} />
                                                <span className="errorMessage error-user-block-337"> {errors.OtherUserTypes}</span>
                                            </Row>
                                            : ''}

                                    </Row>
                                    <Row>
                                    <Col md={6} className="padding-remove-left">
                                        <AvField name="FirstName" placeholder="Name" type="text" maxLength="100"
                                            value={user.FirstName} onChange={(e) => this.onKeyChange(e)}
                                            validate={
                                                {
                                                    required: { value: true, errorMessage: 'Name is required' },
                                                    pattern: { value: '^[A-Za-z ]+$', errorMessage: 'Name is invalid' }
                                                }}
                                        />
                                        </Col>
                                        <Col md={6} className="padding-remove-right">
                                        <AvField name="Company" placeholder="Company Name" type="text" maxLength="200"
                                            value={user.Company}
                                            validate={
                                                {
                                                    pattern: { value: '^[A-Za-z ]+$', errorMessage: 'Company is invalid' }
                                                }}
                                            onChange={(e) => this.onKeyChange(e)}
                                        />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <AvField name="Phone" placeholder={CONFIG.REGION_ID !== REGIONS.UK ? "Phone No: xxx-xxx-xxxx" : "Phone No: xxx xxxx xxxx"} type="tel"
                                            value={user.Phone}
                                            maxLength={CONFIG.REGION_ID !== REGIONS.UK ? "12" : "13"}
                                            validate={
                                                {
                                                    required: { value: true, errorMessage: 'Phone No is required' },
                                                    tel: {
                                                        pattern: CONFIG.REGION_ID !== REGIONS.UK ? /^(\+0?1\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/ : /^(\+0?1\s)?\(?\d{3}\)?[\s.-]\d{4}[\s.-]\d{4}$/, errorMessage: 'Phone No is invalid'
                                                    }
                                                }}
                                            onChange={(e) => this.handlePhoneChange(e)} />
                                    </Row>
                                    <Row>
                                        <AvField name="BillingAddress" placeholder="Street Address" type="textarea" maxLength="300"
                                            value={user.BillingAddress} onChange={(e) => this.onKeyChange(e)}
                                            validate={
                                                {
                                                    pattern: { value: '^[A-Za-z0-9,/. ]+$', errorMessage: 'Address is invalid ' },
                                                    required: { value: true, errorMessage: 'Street Address is required' },
                                                }}
                                        />
                                        <span className="errorMessage"> {errors.BillingAddress}</span>
                                    </Row>
                                    <Row>
                                        <Col md={6} className="padding-remove-left">
                                            <AvField name="BillingCity" placeholder={CONFIG.REGION_ID !== REGIONS.UK ? "City" : "Town/City"} type="text" maxLength="300"
                                                value={user.BillingCity} onChange={(e) => this.onKeyChange(e)}
                                                validate={
                                                    {
                                                        pattern: { value: '^[A-Za-z ]+$', errorMessage: 'City is invalid ' },
                                                        required: { value: true, errorMessage: CONFIG.REGION_ID === REGIONS.UK ? "Town/City is required" : 'City is required' },
                                                    }}
                                            />
                                            <span className="errorMessage"> {errors.BillingCity}</span>
                                        </Col>
                                        <Col md={6} className="padding-remove-right">
                                            <AvField type="select" name="BillingCountry" className="bs" validate={{
                                                required: { value: true, errorMessage: 'Country is required' }
                                            }} disabled onChange={(e) => this.onDDLChange(e)} value={user.BillingCountry}>
                                                <option value='' >--Select Country--</option>
                                                {countryData && countryData.length > 0 ? countryData.map((state) => {
                                                    if (state.Country !== "Scotland" && state.Country !== "Wales" && state.Country !== "Northern Ireland")
                                                        return (
                                                            <option key={state.Country} value={state.CountryCode}>{state.Country === "England" ? 'UK' : state.CountryCode}</option>
                                                        )
                                                }) : ''}
                                            </AvField>
                                        </Col>
                                        {filteredState && filteredState.length > 0 && showState ?
                                            <Col md={6} className="padding-remove-left">
                                                <AvField type="select" name="BillingState" className="bs" validate={{
                                                    required: { value: true, errorMessage: CONFIG.REGION_ID === REGIONS.US ? 'State/Province is required' : 'State is required' }
                                                }} onChange={(e) => this.onDDLChange(e)} value={user.BillingState}>
                                                    <option value='' >{CONFIG.REGION_ID === REGIONS.US ? "--Select State/Province--" : "--Select State--"}</option>
                                                    {filteredState ? filteredState.filter(state => state.StateID !== '').map((state) => {
                                                        return (
                                                            <option key={state.StateID} className={state.StateID === '' ? "optHead disabled-states" : ''} disabled={state.StateID === '' ? true : false} value={state.StateID}>{state.StateCode}</option>
                                                        )
                                                    }) : ''}
                                                </AvField>
                                            </Col>
                                            : ''}
                                        <Col md={6} className="padding-remove-right">
                                            <AvField name="BillingZipCode" placeholder={CONFIG.REGION_ID === REGIONS.UK ? "Postcode" : "Zip Code"} type="text" maxLength="50"
                                                value={user.BillingZipCode} onChange={(e) => this.onKeyChange(e)}
                                                validate={
                                                    {
                                                        pattern: { value: '^[0-9]+$', errorMessage: 'Zip/Postcode is invalid ' },
                                                        required: { value: true, errorMessage: CONFIG.REGION_ID === REGIONS.UK ? "Postcode is required" : 'Zip Code is required' },
                                                    }} />
                                            <span className="errorMessage"> {errors.BillingZipCode}</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <div className="add-new-btn marginall-auto">
                                            <Button type="submit" className="width-100 blue-color-btn">GO TO DASHBOARD</Button>
                                        </div>
                                    </Row>
                                </AvForm>
                            </Col>
                        </Row>
                    </div>
                    : ''}
            </div>
        );
    }
}

export default connect()(UserRegistraton);